<template>
  <div class="flex flex-col min-h-screen overflow-x-hidden">

    <Head>
      <Link href="https://fonts.googleapis.com" rel="preconnect" />
      <Link crossorigin href="https://fonts.gstatic.com" rel="preconnect" />
      <Link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap" media="print"
        onload="this.onload=null; this.removeAttribute('media');" rel="stylesheet" />
      <title>Apex.</title>
    </Head>
    <TheHeader :is-business="true" />
    <div class="flex-1">
      <slot />
    </div>
    <TheBusinessFooter />

    <Notivue v-slot="item">
      <Notification :item="item" />
    </Notivue>
  </div>
</template>