<template>
    <footer class="footer_section bg-black">
        <div class="container_fluid container row-col gap-12 pt-[4.375rem] pb-24 md:pb-12">
            <div class="displace_ grid lg:grid-cols-2 items-start lg:gap-x-10 justify-between">
                <div class="left_side max-w-[45rem] pb-12 lg:pb-0">
                    <div class="about_us">
                        <a class="logo_container w-[6.875rem] inline-block lg:mb-3">
                            <img alt="logo" class="src" height="59.86" src="~/assets/images/logo.svg" width="110">
                        </a>
                        <p class="font-body text-content-4 leading-[1.5rem] md:leading-[1.3125rem] md:text-white text-[0.9375rem] md:text-[0.875rem] font-normal">The most trustworthy cryptocurrency exchange
                            platform
                            available. Where you can have quick access to your
                            money whenever you desire. </p>
                    </div>

                </div>
                <div>
                    <div class="footers_links_container grid grid-cols-2 md:flex gap-8 lg:gap-x-20 w-full">
                        <div v-for="(footer, index) in footerLinks" :key="index"
                            class="feature_box row-col gap-[.625rem] md:gap-4">
                            <p class="title">{{ footer.title }}</p>
                            <ul class="row-col gap-2">
                                <li v-for="(link, index) in footer.links" :key="index"
                                    class="link font-normal font-body break-normal text-gray-2 hover:text-primary-1 text-sm">
                                    <a v-if="footer.title == 'Features'" :href="link.link + link.label">{{ link.label
                                        }}</a>

                                    <NuxtLink v-else :href="link.link">{{ link.label }}</NuxtLink>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright px-4 bg-black pt-8 container border-t border-gray-1 text-center text-content-4">
                <div class="content row-col items-center gap-[1.5rem] md:flex-row-reverse justify-between">
                    <div class="socials flex items-center gap-6">
                        <a v-for="(social, index) in socials" :key="index" :href="social.link"
                            class="inline-flex items-center hover:text-primary-1 text-content-3 justify-center w-[2.5rem] h-[2.5rem] rounded-[50%]">
                            <component :is="social.icon" :alt="social.label" />
                        </a>
                    </div>
                    <p class="font-body text-base">Copyright © {{ currentYear }} Apex. All rights reserved.</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts" setup>
import IconFacebook from "~/components/icons/IconFaceBook.vue";
import IconInstagram from "~/components/icons/IconInstagram.vue";
import IconTwitter from "~/components/icons/IconTwitter.vue";
import IconLinkedin from "~/components/icons/IconLinkedin.vue";

const currentYear = computed(() => new Date().getFullYear());

const socials = [
    {
        label: "Twitter",
        link: "https://twitter.com/useapexnetwork",
        icon: IconTwitter
    },
    {
        label: "LinkedIn",
        link: "https://www.linkedin.com/company/apex-network-limited/mycompany/",
        icon: IconLinkedin
    },
    {
        label: "Facebook",
        link: "https://web.facebook.com/useapexnetwork",
        icon: IconFacebook
    },
    {
        label: "Instagram",
        link: "https://www.instagram.com/useapexnetwork/",
        icon: IconInstagram
    }
];

const footerLinks = [
    {
        title: "Features",
        links: [
            {
                label: 'Sell Cryptocurrency',
                link: "/services",
                name: "#swap"
            },
            {
                label: "Sell Gift cards",
                link: "/services",
                name: "#gift-cards"

            },
        ]
    },
    {
        title: "Company",
        links: [
            {
                label: "About Apex Network",
                link: "/about-us"
            },
            {
                label: "Contact Us",
                link: "/contact-us"
            },
            {
                label: "Blog",
                link: "https://blog.apexnetwork.co/"
            },
        ]
    }, {
        title: "Others",
        links: [
            {
                label: "Marketing",
                link: "https://welcome.apexnetwork.co/"
            },
            {
                label: "Terms of Use",
                link: "/terms-and-conditions"
            },
            {
                label: "Privacy Notice",
                link: "/privacy-notice"
            },
        ]
    }
];
</script>

<style lang="scss" scoped>
.store_btn_container {
    @apply lg:flex-row;
}

.copyright a img {
    @apply w-6 h-6;
}
</style>
